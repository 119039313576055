import { AzureBlobStorageClient } from './AzureBlobStorageClient';
import { AWSS3Client } from './AWSS3Client';

export const CLOUD_PROVIDER: CloudProviders | undefined = process.env.REACT_APP_CLOUD_PROVIDER?.toUpperCase() as CloudProviders;

export type CloudProviders = 'AWS' | 'AZURE';
export interface StorageClient {
    projectId: string;
    uploadFile(
        file: File,
        prefix?: string,
        uploadProgressCallback?: (data: { progress: number; name: string }) => void,
        uploadSuccessCallback?: (name: string) => void,
        uploadErrorCallback?: (error: { code: string; message: string }) => void,
        overwrite?: boolean
    ): Promise<any>;
    uploadFilesSync(files: File[], prefix?: string, overwrite?: boolean): Promise<any>;
    editFile(
        newPath: string,
        originalPath: string,
        overwrite?: boolean,
        uploadProgressCallback?: (data: { progress: number; name: string }) => void,
        uploadSuccessCallback?: (name: string) => void,
        uploadErrorCallback?: (error: { code: string; message: string }) => void
    ): Promise<any>;

    abortFileUpload(): boolean;
}
class FileManagerClient {
    private cloudProvider: CloudProviders;
    private storageClient: StorageClient;

    private static _instance: FileManagerClient;

    set projectId(value: string) {
        this.storageClient.projectId = value;
    }

    get projectId() {
        return this.storageClient.projectId;
    }

    public static get Instance() {
        return this._instance || (this._instance = new this(CLOUD_PROVIDER));
    }
    private constructor(cloudProvider?: CloudProviders) {
        switch (cloudProvider) {
            case 'AZURE':
                this.storageClient = new AzureBlobStorageClient();
                break;
            case 'AWS':
                this.storageClient = new AWSS3Client();
                break;
            default:
                console.log(`The Specified Cloud Provider "${cloudProvider || 'NONE'}" is NOT available `);
                this.storageClient = new AzureBlobStorageClient();
        }
        this.cloudProvider = cloudProvider || 'AZURE';
    }

    async uploadFile(
        file: File,
        prefix?: string,
        uploadProgressCallback?: (data: { progress: number; name: string }) => void,
        uploadSuccessCallback?: (name: string) => void,
        uploadErrorCallback?: (error: { code: string; message: string }) => void,
        overwrite?: boolean
    ) {
        return this.storageClient.uploadFile(file, prefix, uploadProgressCallback, uploadSuccessCallback, uploadErrorCallback, overwrite);
    }

    async uploadFilesSync(files: File[], prefix?: string, overwrite?: boolean) {
        return this.storageClient.uploadFilesSync(files, prefix, overwrite);
    }

    editFile(
        newPath: string,
        originalPath: string,
        overwrite?: boolean,
        uploadProgressCallback?: (data: { progress: number; name: string }) => void,
        uploadSuccessCallback?: (name: string) => void,
        uploadErrorCallback?: (error: { code: string; message: string }) => void
    ) {
        return this.storageClient.editFile(
            newPath,
            originalPath,
            overwrite,
            uploadProgressCallback,
            uploadSuccessCallback,
            uploadErrorCallback
        );
    }

    abortFileUpload() {
        return this.storageClient.abortFileUpload();
    }
}

export default FileManagerClient.Instance;
