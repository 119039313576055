import { CompleteMultipartUploadCommand, CreateMultipartUploadCommand, S3Client, UploadPartCommand } from '@aws-sdk/client-s3';
import _ from 'lodash';
import { sanitizeFileName } from '../../fnString';
import { awsS3BaseUrl } from '../../Globals';
import configServiceAPI from '../configServiceAPI';
import { DEFAULT_ERROR_CODE, maxFileSize } from './AzureBlobStorageClient';
import { StorageClient } from './FileManagerClient';

export class AWSS3Client implements StorageClient {
    private _projectId: string = '';
    set projectId(value: string) {
        this._projectId = value;
    }
    private S3Client: S3Client | null = null;
    private S3Bucket: string = '';
    private S3Prefix: string = 'static-assets';
    private S3ClientUrl: string = '';

    get projectId() {
        return this._projectId;
    }
    private _abortController: AbortController | null = null; // used for eventually aborting an upload. since we support only one upload at a time, a single abort controller is enough

    private async getAWSConfig() {
        const result = await configServiceAPI.getClientConfig(this._projectId);
        if (!result.response) return;

        const data = result.response as any;
        this.S3Bucket = data.container;
        this.S3ClientUrl = data.clientUrl;
    }

    private async getAWSToken(fileSize?: number) {
        this.S3Client = null;
        if (!this.S3Bucket || !this.S3ClientUrl) {
            await this.getAWSConfig();
            if (!this.S3Bucket || !this.S3ClientUrl) return;
        }

        const result = await configServiceAPI.getUploadToken(this._projectId, fileSize);
        const credentials = result.response as any;
        if (!credentials) return;

        this.S3Client = new S3Client({
            region: credentials.region,
            credentials: credentials.credentials
        });
    }

    abortFileUpload(): boolean {
        if (!this._abortController) return false;
        this._abortController.abort();
        return true;
    }

    async editFile(
        newPath: string,
        originalPath: string,
        overwrite?: boolean,
        uploadProgressCallback?: (data: { progress: number; name: string }) => void,
        uploadSuccessCallback?: (name: string) => void,
        uploadErrorCallback?: (error: { code: string; message: string }) => void
    ): Promise<any> {
        const file = (await configServiceAPI.getFile(originalPath)).response as any;
        await this.getAWSToken(file?.size);
        if (!this.S3Client) {
            return {
                success: false,
                error: {
                    message:
                        'Uploader could not be initialized. You might not have rights to perform this action. If you are sure you do, please try to reload the page.',
                    code: DEFAULT_ERROR_CODE
                },
                status: 401
            };
        }

        const fileUrl = `${this.S3ClientUrl}/${this.S3Prefix}/${originalPath}`;
        const fileName = _.last(newPath.split('/')) || '';
        const prefix = newPath.replace(`/${fileName}`, '');

        fetch(fileUrl).then(async (response) => {
            try {
                const blob = await response.blob();
                const name = sanitizeFileName(fileName);
                const key = `${this.S3Prefix}/${prefix}/${fileName}`;
                // Step 1: Start the multipart upload and get the upload ID
                const createMultipartUploadCommand = new CreateMultipartUploadCommand({
                    Bucket: this.S3Bucket,
                    Key: key,
                    ACL: 'public-read-write'
                });
                const uploadId = (await this.S3Client?.send(createMultipartUploadCommand))?.UploadId;

                const partSize = 5 * 1024 * 1024; // 5MB is the minimum part size
                const fileChunks = [];

                for (let start = 0; start < blob.size; start += partSize) {
                    const end = Math.min(start + partSize, blob.size);
                    fileChunks.push(blob.slice(start, end));
                }

                let uploadedBytes = 0;

                const uploadChunk = async (chunk: any, partNum: number) => {
                    const uploadPartCommand = new UploadPartCommand({
                        Bucket: this.S3Bucket,
                        Key: key,
                        PartNumber: partNum,
                        UploadId: uploadId,
                        Body: chunk
                    });

                    const uploadPartOutput = await this.S3Client?.send(uploadPartCommand);
                    uploadedBytes += chunk.size;
                    uploadProgressCallback?.({ progress: (uploadedBytes / blob.size) * 100, name });
                    return {
                        ETag: uploadPartOutput?.ETag,
                        PartNumber: partNum
                    };
                };

                const parts = await Promise.all(fileChunks.map((chunk, index) => uploadChunk(chunk, index + 1)));

                await this.S3Client?.send(
                    new CompleteMultipartUploadCommand({
                        Bucket: this.S3Bucket,
                        Key: key,
                        UploadId: uploadId,
                        MultipartUpload: { Parts: parts }
                    })
                );
                uploadSuccessCallback?.(name);
            } catch (err) {
                console.error('Error in file upload', err);
                const error = {
                    code: DEFAULT_ERROR_CODE,
                    message: err.message || err
                };
                uploadErrorCallback?.(error);
            }
        });
        return { success: true };
    }

    async uploadFilesSync(files: File[], prefix?: string, overwrite?: boolean): Promise<any> {
        //TODO: this needs a rewrite
        return [];
    }

    async uploadFile(
        file: File,
        prefix?: string,
        uploadProgressCallback?: (data: { progress: number; name: string }) => void,
        uploadSuccessCallback?: (name: string) => void,
        uploadErrorCallback?: (error: { code: string; message: string }) => void,
        overwrite?: boolean
    ): Promise<any> {
        if (file.size > maxFileSize) {
            return {
                success: false,
                error: {
                    message: 'The file is too big. Maximum file size allowed is 5GB. Please upload a smaller file',
                    code: DEFAULT_ERROR_CODE
                },
                status: 400
            };
        }
        await this.getAWSToken(file.size);
        if (!this.S3Client) {
            return {
                success: false,
                error: {
                    message:
                        'Uploader could not be initialized. You might not have upload rights. If you are sure you do, please try to reload the page.',
                    code: DEFAULT_ERROR_CODE
                },
                status: 401
            };
        }
        try {
            const name = sanitizeFileName(file.name);
            const key = `${this.S3Prefix}/${prefix}/${name}`;
            // Step 1: Start the multipart upload and get the upload ID
            const createMultipartUploadCommand = new CreateMultipartUploadCommand({
                Bucket: this.S3Bucket,
                Key: key,
                ACL: 'public-read-write'
            });
            const uploadId = (await this.S3Client?.send(createMultipartUploadCommand))?.UploadId;

            const partSize = 5 * 1024 * 1024; // 5MB is the minimum part size
            const fileChunks = [];

            for (let start = 0; start < file.size; start += partSize) {
                const end = Math.min(start + partSize, file.size);
                fileChunks.push(file.slice(start, end));
            }

            let uploadedBytes = 0;

            const uploadChunk = async (chunk: any, partNum: number) => {
                const uploadPartCommand = new UploadPartCommand({
                    Bucket: this.S3Bucket,
                    Key: key,
                    PartNumber: partNum,
                    UploadId: uploadId,
                    Body: chunk
                });

                const uploadPartOutput = await this.S3Client?.send(uploadPartCommand);
                uploadedBytes += chunk.size;
                uploadProgressCallback?.({ progress: (uploadedBytes / file.size) * 100, name });
                return {
                    ETag: uploadPartOutput?.ETag,
                    PartNumber: partNum
                };
            };

            Promise.all(fileChunks.map((chunk, index) => uploadChunk(chunk, index + 1))).then(async (parts) => {
                await this.S3Client?.send(
                    new CompleteMultipartUploadCommand({
                        Bucket: this.S3Bucket,
                        Key: key,
                        UploadId: uploadId,
                        MultipartUpload: { Parts: parts }
                    })
                );
                uploadSuccessCallback?.(name);
            });
        } catch (err) {
            console.error('Error in file upload', err);
            const error = {
                code: DEFAULT_ERROR_CODE,
                message: err.message || err
            };
            uploadErrorCallback?.(error);
        }
        return { success: true };
    }
}
